
import { Options, Vue } from 'vue-class-component'

import api from '@/api'
import store from '../../store'
import { toDate } from '../../utils/common'

@Options({
  components: {}
})
export default class investor extends Vue {
  toDate = toDate;
  table = [];
  loading = false

  created () {
    this.loading = true
    api
      .getInfoVerify(store.getters['company/get'])
      .then((data: any) => {
        this.table = data.partners
      }).finally(() => {
        this.loading = false
      })
  }
}
