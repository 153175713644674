import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.table,
      stripe: "",
      "empty-text": "没有记录",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          align: "center",
          type: "index",
          label: "序号",
          width: "50"
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "stockName",
          label: "投资人",
          "min-width": "150"
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "stockType",
          label: "投资人类型",
          "min-width": "90"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.stockType || '-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "stockPercent",
          label: "出资比例"
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "shouldCapi",
          label: "认缴出资额",
          "min-width": "90"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.shouldCapi+'万元'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "shoudDate",
          label: "认缴出资时间",
          "min-width": "110"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.toDate(scope.row.shoudDate,'YYYY-MM-DD')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "realCapi",
          label: "实缴出资额",
          "min-width": "90"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.realCapi?scope.row.realCapi+'万元':'-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "capiDate",
          label: "实缴时间"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.toDate(scope.row.capiDate,'YYYY-MM-DD')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "tagsList",
          label: "股东标签",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            (scope.row.tagsList.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.tagsList, (item, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(index?'、':'') + _toDisplayString(item), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          prop: "finalBenefitPercent",
          label: "最终受益股份",
          "min-width": "100"
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          label: "关联产品"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.relatedProduct?scope.row.relatedProduct.name:'-'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "center",
          label: "关联机构"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.relatedOrg?scope.row.relatedOrg.name:'-'), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}